import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getLogoAndIcon({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/imagesSettings/getLogoAndIcon')
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    logoChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/hero/imagesSettings/logoChange', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    iconChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/hero/imagesSettings/iconChange', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getSplashScreen({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/imagesSettings/getSplashScreen')
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getHomeBackground({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/hero/imagesSettings/getHomeBackground')
          .then(response => {
            // commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    splashScreenChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/hero/imagesSettings/splashScreenChange', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    HomeBackgroundChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/application/hero/imagesSettings/HomeBackgroundChange', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getSlide({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/imagesSettings/getSlide?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    addSlide({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/imagesSettings/addSlide', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateSlide({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/imagesSettings/updateSlide', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
